const observer = new IntersectionObserver(
  function (entries, { rootMargin }) {
    entries.forEach(function (entry) {
      const animation = entry.target.getAttribute("data-animation");

      if (entry.isIntersecting) {
        entry.target.classList.add("animated", `${animation}`);
      }
    });
  },
  { rootMargin: "0px 0px -15% 0px" }
);

const animatedEls = document.querySelectorAll("[data-animation]");
animatedEls.forEach(function (el) {
  return observer.observe(el);
});
